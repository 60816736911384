* {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
}
.body {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}